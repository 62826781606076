import Cookies from 'js-cookie';

const storageKey = 'my_downloads';
const storageDurationDays = 7;

const updateDownloadsDummary = () => {
    const summaryElement = document.querySelector('[data-my-downloads-summary]');
    if (!summaryElement) {
        return;
    }

    const downloads = getSelectedDownloads();
    if (!downloads.length) {
        summaryElement.hidden = true;
        return;
    }

    summaryElement.hidden = false;
    const summaryLink = summaryElement.querySelector('a');
    const textSuffix = downloads.length === 1 ? 'ausgewählter Download' : 'ausgewählte Downloads';
    summaryLink.innerHTML = `<span class="my-downloads-summary__count">${downloads.length}</span> ${textSuffix}`;
};

/**
 * Get the selected downloads.
 * @returns {string[]} An array of IDs of the selected downloads.
 */
const getSelectedDownloads = () => {
    const downloads = Cookies.get(storageKey);
    return downloads ? downloads.split(',') : [];
};

/**
 * Save the selected downloads to permanent storage.
 * @param {string[]} downloads An array of download IDs.
 */
const saveSelectedDownloads = downloads => {
    if (downloads) {
        Cookies.set(storageKey, downloads.join(','), {
            expires: storageDurationDays,
            sameSite: 'Lax',
        });
    } else {
        Cookies.remove(storageKey);
    }

    updateDownloadsDummary();
};

/**
 * Add a download to the existing selection by ID.
 * @param {string} id The ID of the download to add.
 */
const addSelectedDownload = id => {
    const downloads = getSelectedDownloads();
    if (downloads.includes(id)) return;

    downloads.push(id);
    saveSelectedDownloads(downloads);
};

/**
 * Remove a download from the existing selection.
 * @param {string} id The ID of the download to remove.
 */
const removeSelectedDownload = id => {
    const downloads = getSelectedDownloads();
    const remaining = downloads.filter(current => current !== id);
    saveSelectedDownloads(remaining);
};

/**
 * Remove all selected Downloads.
 */
const clearSelectedDownloads = () => saveSelectedDownloads([]);

/**
 * Handle initial state on page load.
 */

updateDownloadsDummary();

const addDownloadButtons = document.querySelectorAll('[data-my-downloads-add]');
const selectedDownloads = getSelectedDownloads();

addDownloadButtons.forEach(button => {
    const elementId = button.dataset.myDownloadsAdd;

    const replaceButton = (transition = false) => {
        const replacementElement = document.querySelector(
            `[data-my-downloads-replace="${elementId}"]`,
        );

        if (!transition) {
            button.hidden = true;
            if (replacementElement) {
                replacementElement.hidden = false;
            }
            return;
        }

        button.classList.add('fade-out');
        button.addEventListener('animationend', () => {
            button.hidden = true;
            if (replacementElement) {
                replacementElement.hidden = false;
            }
        });
    };

    if (selectedDownloads.includes(elementId)) {
        replaceButton();
    }

    button.addEventListener('click', e => {
        addSelectedDownload(elementId);
        replaceButton(true);
    });
});

const removeButtons = document.querySelectorAll('[data-my-downloads-remove]');
removeButtons.forEach(button =>
    button.addEventListener('click', e => {
        e.preventDefault();
        removeSelectedDownload(button.dataset.myDownloadsRemove);
        location.reload();
    }),
);

const resetButtons = document.querySelectorAll('[data-my-downloads-reset]');
resetButtons.forEach(button =>
    button.addEventListener('click', e => {
        e.preventDefault();
        clearSelectedDownloads();
        location.reload();
    }),
);
